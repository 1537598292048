/* ==========================================================================
   Steel theme skin
   ========================================================================== */

/* Colors */
$base-color: #3a4750 !default;
$text-color: #303841 !default;
$accent-color: #d72323 !default;
$background-color: #eee !default;
$code-background-color: #fff !default;
$border-color: rgba($text-color, 0.25) !default;
$overlay-color: #fff !default;
