/* ==========================================================================
   Navicons
   ========================================================================== */

.navicon-button {
  display: inline-block;
  position: relative;
  margin: 0.90625rem 0;
  padding: 0.90625rem 1rem;
  transition: $navicon-duration / 2;
  border: 0;
  outline: none;
  background-color: $navicon-nav-bg-close;
  line-height: 5 * $navicon-height;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  @include breakpoint($medium) {
    margin-right: 2rem;
  }

  @include breakpoint($large) {
    margin-right: 5vw;
  }

  &.open {
    background-color: $navicon-nav-bg-open;
  }

  .navicon::before,
  .navicon::after {
    transition-duration: $navicon-duration / 2;
  }

  &:hover {
    transition-duration: $navicon-duration;

    .navicon::before,
    .navicon::after {
      transition-duration: $navicon-duration / 2;
    }

    .navicon::before { top: (2.5 * $navicon-height); }
    .navicon::after { top: (-2.5 * $navicon-height); }
  }
}

.navicon {
  position: relative;
  width: $navicon-width;
  height: $navicon-height;
  transition-duration: $navicon-duration;
  border-radius: $navicon-width;
  background: $navicon-content-bg;

  &::before,
  &::after {
    display: block;
    position: absolute;
    width: $navicon-width;
    height: $navicon-height;
    transition-duration: $navicon-duration $navicon-duration / 2;
    border-radius: $navicon-width;
    background: $navicon-content-bg;
    content: '';
  }

  &::before { top: (2 * $navicon-height); }
  &::after { top: (-2 * $navicon-height); }
}

.open:not(.steps) .navicon::before,
.open:not(.steps) .navicon::after {
  top: 0 !important;
}

.open {
  transform: scale($navicon-toggled-size);

  .navicon::before,
  .navicon::after {
    transition-duration: $navicon-duration;
  }

  /* Arrows */
  &.larr .navicon,
  &.rarr .navicon,
  &.uarr .navicon {

    &::before,
    &::after {
      width: (0.6 * $navicon-width);
    }

    &::before {
      transform: rotate(35deg);
      transform-origin: left top;
    }

    &::after {
      transform: rotate(-35deg);
      transform-origin: left bottom;
    }
  }

  &.uarr {
    transform: scale($navicon-toggled-size) rotate(90deg);
  }

  &.rarr .navicon {

    &::before {
      transform: translate3d(1em, 0, 0) rotate(-35deg);
      transform-origin: right top;
    }

    &::after {
      transform: translate3d(1em, 0, 0) rotate(35deg);
      transform-origin: right bottom;
    }
  }
}
