/* ==========================================================================
   Site-wide
   ========================================================================== */

.site-title {
  @include fluid-type($min-vw, $max-vw, 20px, 24px);
  margin: 0;
  padding: 1.8125rem 1rem;
  padding-right: calc(10vw + #{$navicon-width}); /* make room for sidebar toggle */
  font-family: $base-font-family;
  font-weight: bold;
  line-height: 1;

  @include breakpoint($medium) {
    padding-left: 2rem;
  }

  @include breakpoint($large) {
    padding-left: 5vw;
  }

  a {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: $site-image-height;
    color: $text-color;
    text-decoration: none;
  }
}

.site-logo {
  width: $site-image-width;
  height: $site-image-height;
  margin-right: 0.5rem;
  border-radius: 50%;
}
