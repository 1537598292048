/* ==========================================================================
   Footer
   ========================================================================== */

.site-footer {
  margin-top: 4rem;
  color: tint($text-color, 40%);
  font-size: 80%;
}

.copyright {
  font-family: $monospace-font-family;
}
